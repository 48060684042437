import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
          image
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata

  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
    defaults.baseUrl = window.location.origin;
  }

  if (defaults.baseUrl === '') {
    console.error('Please set a baseUrl in your site metadata!');
    return null;
  }

  const title = props.title || defaults.title;
  const description = props.description || defaults.description;
  const url = new URL(props.path || '', defaults.baseUrl);
  const image = new URL(props.image || defaults.image);
  const socialTitle = props.socialTitle || title
  const socialDescription = props.socialDescription || description

  return (
    <Helmet titleTemplate={`%s | ${defaults.title}`}>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:image" content={'http://dk0xyuea3a8kt.cloudfront.net/hogh-social.png'} />
      <meta property="og:image:secure_url" content={'https://dk0xyuea3a8kt.cloudfront.net/hogh-social.png'} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:description" content={socialDescription} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

export default SEO;
