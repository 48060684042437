/**
 * Basic theme
 */

const palette = {
  primary: {
    contrast: 'white',
    main: '#001a78',
    banner: '#cbebf1',
    accent: '#fc3d45',
    dark: '#181818'
  },
  secondary: {
    contrast: '#aaa',
    main: '#242424',
    dark: '#181818',
  },
};

const baseSize = 16;
const baseFontFamily = 'Noto Sans';

const typography = {
  baseFontFamily,
  baseFontSize: `${baseSize}px`,
};

const zIndex = {
  header: 10,
  overlay: 15,
  drawer: 20,
  headerText: 25,
};

export default {
  palette,
  typography,
  zIndex,
  size: size => `${size * baseSize}px`,
};
